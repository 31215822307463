<template>
  <div>
    <b-row class="px-2">
      <b-col class="mb-2" cols="4">
        <h1 class="mb-0">Gestão de remessas</h1>
      </b-col>
      <b-col class="mb-2" cols="8">
        <div class="d-flex align-items-center justify-content-end" v-for="manualImportConfig in manualImportConfigs">
          <b-button class="primary"  @click="scheduleManualImport(manualImportConfig)">
            <span class="mr-25 align-middle">{{ manualImportConfig.descricao }}</span>
            <feather-icon
              icon="DownloadCloudIcon"
              size="12"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <datatable
          ref="concessoesDatatable"
          :customFields="customFields"
          :customActions="customActions"
          :showExportToExcell="true"
          :exportToExcellAction="downloadRemittancesXlsx"
        >
          <template #table-header-actions>
            <div class="d-flex justify-content-start align-items-center">
              <input
                type="text"
                v-model="filteredNumber"
                class="form-control mr-2"
                placeholder="Buscar por número"
              />
              <v-select
                v-model="selectedAssignor"
                :options="assignors"
                class="assignor-selector d-flex flex-column mr-2"
                label="nome"
                placeholder="Cedente"
              >
              </v-select>
              <v-select
                v-model="selectedStatus"
                :options="statuses"
                class="assignor-selector d-flex flex-column mr-2"
                placeholder="Status"
              >
              </v-select>
              <flat-pickr
                v-model="selectedDate"
                class="assignor-selector form-control bg-white"
                :config="config"
                placeholder="Busca por data"
              />
            </div>
          </template>
        </datatable>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import http from "@/services/http";

import {
  BCol,
  BRow,
  BCard,
  BModal,
  BTable,
  BBadge,
  BButton,
  BTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
  remessaStatusBadge,
  moneyFormatDatatable,
  brazilianFormattedDate,
  statusRegistroRemessaBadge,
} from '@core/comp-functions/data_visualization/datatable'
import downloadFile from '@/utils/download-file'

export default {
  name: 'Concessoes',
  components: {
    BCol,
    BRow,
    BCard,
    BTable,
    BModal,
    BBadge,
    BButton,
    vSelect,
    BTooltip,
    flatPickr,
    Datatable,
    BFormCheckbox,
    http
  },
  data() {
    return {
      manualImportConfigs: [],
      selectedDate: '',
      selectedStatus: null,
      selectedAssignor: null,
      filteredNumber: '',
      assignors: [],
      statuses: [
        { value: 'importado', label: 'Importado' },
        { value: 'disponivel', label: 'Disponivel' },
        { value: 'aguardando_assinatura', label: 'Aguardando assinatura' },
        { value: 'assinada', label: 'Assinada' },
        { value: 'concluido', label: 'Concluída' },
        { value: 'erro', label: 'Erro' },
        { value: 'cancelada', label: 'Cancelada' },
      ],
      config: {
        mode: 'range',
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
      customFields: [
        {
          label: 'Número',
          key: 'numero',
          sortable: true,
        },
        {
          label: 'Cedente',
          key: 'cedente',
          sortable: true,
        },
        {
          label: 'Data de envio',
          key: 'created_at',
          sortable: true,
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Qtd. de títulos',
          key: 'quantidade_titulos',
        },
        {
          label: 'Valor de face',
          key: 'total_valor_face',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor operado',
          key: 'total_valor_operado',
          sortable: true,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Registro',
          key: 'status_registro',
          formatter: statusRegistroRemessaBadge,
          sortable: true,
        },
        {
          label: 'Status',
          key: 'status',
          formatter: remessaStatusBadge,
        },
      ],
      customActions: [
        {
          label: 'Visualizar',
          icon: 'icon-search',
          action: 'show',
          name: 'remessas.show',
        },
      ],
    }
  },
  async mounted() {
    await this.getAssignors()
    await this.getManualImports()
  },
  methods: {
    async scheduleManualImport(manual){
      this.$swal({
        title: 'Confirmar importação?',
        text: `${manual.descricao} será iniciada após a confirmação`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#289f75',
        cancelButtonColor: '#fc065e',
      }).then((result) => {
        if (result.value) {
          http.post(`/recebiveis/importacoes_manuais/schedule`, { key: manual.key })
          this.$swal.fire({
            title: 'Atualizando dados!',
            html: 'A importação esta em andamento!<br><br>O processo irá acontecer em segundo plano e pode levar alguns instantes, não é necessario ficar nesta página!',
            icon: 'success',
          })
          .then((result) => {
            this.$emit('closeForm')
          })
        }
      })
    },

    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data
    },
    async getManualImports() {
      const data = await this.$store.dispatch('admin/getManualImports')
      this.manualImportConfigs = data.data
      console.log('this.manualImportConfigs: ', this.manualImportConfigs)
    },
    async downloadRemittancesXlsx() {
      try {
        const { data } = await this.$store.dispatch('admin/exportRemittances', this.$refs.concessoesDatatable.params)
        downloadFile(data, 'remessas.xlsx', 'text/xlsx')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Ocorreu um erro ao tentar baixa o arquivo.'
        })
      }
    }
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.id
      if (this.selectedStatus) params.status = this.selectedStatus.value
      if (this.filteredNumber) params.numero = this.filteredNumber
      if (this.selectedDate.includes(' até ')) {
        let [inicio, fim] = this.selectedDate.split(' até ')
        params.data_inicio = inicio;
        params.data_fim = fim;
      } else {
        params.data_inicio = this.selectedDate
      }

      if (this.datatableFilterValues) params = { ...params, ...this.datatableFilterValues }

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.concessoesDatatable.updateFilterValues(newValue)
    }
  },
}
</script>

<style>
.assignor-selector {
  min-width: 240px;
}
</style>
